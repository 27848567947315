import React from 'react'
import { useLocation } from '@reach/router';


const Main = ({title, text, text2}) => {

  const location = useLocation()
  const pathname = location.pathname
  const borrowersPath = '/borrowers/'

  return (
    <section className={`section-main ${(pathname === borrowersPath) && 'borrowers-main'}`}>
      <div className="row justify-content-center">
        <div className='col-xs-12 col-sm-6 col-lg-5 col-xl-6 col-xxl-4'>
        <p className="main-title">{title}</p>
        </div>
        <div className='col-1 d-xs-none d-sm-none d-lg-none d-xl-none d-xxl-block'/>
        <div className='col-xs-12 col-sm-6 col-lg-5 col-xl-5 col-xxl-4 d-flex'>
        <p className="main-text">
        {text}
        {
          text2 && 
          <p className="main-text mt-3"> 
          {text2}
          </p>
        }
        </p>
        </div>
      </div>
      </section>
  )
}

export default Main