import React from "react";
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";

const MailChimpForm = () => {
const url = "https://finance.us6.list-manage.com/subscribe/post?u=8c685b8ccec7a4e3dadde86e1&amp;id=3f9aceb09d";

  const {
      loading,
      error,
      success,
    //   message,
      handleSubmit
    } = useMailChimpForm(url);
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: "",
  });
  return (
    <div>
      <form
        onSubmit={event => {
          event.preventDefault();
          handleSubmit(fields);
        }}
        className="silta-newsletter-form"
      >
        <input
          id="EMAIL"
          // autoFocus
          type="email"
          value={fields.EMAIL}
          onChange={handleFieldChange}
        />
        <button type='submit'>SUBSCRIBE</button>
      </form>
      {loading && <p className='msg-alert'>Submitting..</p>}
      {error && <p className='msg-alert msg-alert-error'>Please enter a valid email address</p>}
      {success && <p className='msg-alert msg-alert-success'>Done! Please check your email.</p>}
    </div>
      );
}

export default MailChimpForm;