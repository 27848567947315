import React from 'react'
import MailChimpForm from './MailChimpForm'


const Newsletter = () => {
  return (
    <section className="section-newsletter" id="sectionNewsletter">
      <div className="container">
            <div className='newsletter-content'>
          <div className='newsletter-title-container'>
            <h2 className="section-title"><span className="title-dot" />SILTA NEWSLETTER</h2>
            <p className='newsletter-text'>Sign up for a monthly email with the latest Silta updates.</p>
          </div>
        <div className='form-container'>
         <div className=''>
         <MailChimpForm />
          </div>
          <div>
            <p className="pp-info">We'll handle your data according to our <a href="/privacy-policy" target="_blank">Privacy
              Policy</a>
            </p>
          </div>
        </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter